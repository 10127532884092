<template>
  <div>
    <v-app-bar
      :dark="dark"
      :color="color"
      dense
      flat
      fixed
      :app="app"
      style="z-index: 1"
      :height="height"
      clipped-right

      :class="{
        appBarBottomBorder: divider,
      }"
    >
      <v-row wrap no-gutters class="pa-0" align="center">
        <slot name="toolbarIcon"></slot>

        <!-- Slot used to change the name and add a button etc -->

        <slot name="toolbarTitle"></slot>

        <v-spacer></v-spacer>
        <slot name="toolbarRight"></slot>

        <template v-if="selectedOrganisation">


          <userAvatar
            :size="26"
            :last_name="selectedOrganisation.name"
            rounded
            show_name_in_tooltip
            :avatar_url="selectedOrganisation.picture_url"

          />
          <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                outlined
                :color="dark ? 'white--text' : 'button_text_grey'"
                class="font-weight-medium mx-4"
                @click="inviteDialog = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small class="pr-2">mdi-account-multiple-plus</v-icon>
                {{ $t("misc.invite") }}
              </v-btn>
            </template>

            <span>
              {{
                $t("organisations.invitePeopleTo") + selectedOrganisation.name
              }}</span
            >
          </v-tooltip>
        </template>
        <!-- Hide notifications for the moment -->
        <!-- <v-btn class="mx-2" small @Click="openNotificationMenu()" icon>
          <img width="22px" src="/images/svg_icons/icon_notifications.svg" />
        </v-btn> -->

        <v-menu
          style="z-index: 1000000000"
          bottom
          flat
          content-class="white elevation-4 rounded-lg"
          offset-y
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-2" small icon v-bind="attrs" v-on="on">
              <userAvatar
                v-if="currentUser"
                :first_name="currentUser.first_name"
                :last_name="currentUser.last_name"
                :avatar_url="currentUser.avatar_url"
                :email="currentUser.email"
              />
            </v-btn>
          </template>

          <!--   <v-list>
          <v-list-item v-for="n in 5" :key="n" @click="() => {}">
            <v-list-item-title>Option {{ n }}</v-list-item-title>
          </v-list-item>
        </v-list> -->

          <v-card
            flat
            class="text-center"
            width="340"
            style="max-height: calc(100vh - 62px - 100px)"
          >
            <v-card-title class="pb-5">
              <v-row align="center" v-if="currentUser">
                <v-col cols="12">
                  <userAvatar
                    :first_name="currentUser.first_name"
                    :last_name="currentUser.last_name"
                    :size="76"
                    :avatar_url="currentUser.avatar_url"
                    :email="currentUser.email"
                /></v-col>

                <v-col cols="12" class="font-weight-bold body-2 pb-0">
                  <span>{{
                    [currentUser.first_name, currentUser.last_name].join(" ")
                  }}</span>
                </v-col>
                <v-col cols="12" class="caption pt-0">
                  <span>{{ currentUser.email }}</span>
                </v-col>
                <v-col cols="12" class="pt-1" v-if="!hideSettings">
                  <v-btn small outlined rounded to="/settings">
                    {{ $t("userProfile.editProfile") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card-text class="pa-0" v-if="!hideSettings">
              <v-divider></v-divider>

              <!-- Organisation ( not using v select to avoid issue with menu content click etc ) -->

              <v-list dense two-line class="py-0 text-start">
                <template v-for="org in organisations">
                  <v-list-item
                    two-line
                    ripple
                    @click="goToOrganisation(org.id)"
                    :key="org.id"
                    :class="{
                      'v-list-item--active primary--text':
                        selectedOrganisationId == org.id,
                    }"
                    v-if="!preventOrgSwitch || (preventOrgSwitch && selectedOrganisationId == org.id)"
                  >
                    <v-list-item-action class="mr-4">
                      <userAvatar
                        :size="32"
                        :last_name="org.name"
                        rounded
                        :avatar_url="org.picture_url"
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ org.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ org.nb_members }} members
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
              <!-- <v-select
                v-if="selectedOrganisationId"
                :value="selectedOrganisationId"
                dense
                :items="organisations"
                item-text="name"
                item-value="id"
                persistent-hint
                solo
                flat
                background-color="white"
                :menu-props="{ bottom: true, offsetY: true }"
              >
                <template v-slot:prepend-item>
                  <v-subheader class="pl-4">
                    {{ $t("organisations.selectOrganisation") }} :
                  </v-subheader>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item
                    two-line
                    ripple
                    @click="selectOrganisationId(item.id)"
                  >
                    <v-list-item-action class="mr-4">
                      <userAvatar
                        :size="32"
                        :last_name="item.name"
                        rounded
                        :avatar_url="item.picture_url"
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.nb_members }} members
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select> -->

              <v-divider></v-divider>
              <div v-if="!$auth.loading">
                <button v-if="!$auth.isAuthenticated" @click="login">
                  Log in
                </button>

                <v-btn
                  v-else
                  small
                  depressed
                  color="transparent"
                  class="px-5 my-5"
                  @click="logout"
                >
                  <b> {{ $t("userProfile.logOut") }} </b>
                  <v-icon small class="pl-2">mdi-logout</v-icon>
                </v-btn>
              </div>

              <v-divider></v-divider>

              <v-col cols="12" class="pt-4 pb-0">
                <v-btn
                  small
                  depressed
                  color="white"
                  :href="privacyLink"
                  target="_blank"
                >
                  <span class="caption"> {{ $t("misc.privacyPolicy") }}</span>
                </v-btn>
              </v-col>
              <v-col cols="12" class="pb-4 pt-0">
                <v-btn
                  small
                  depressed
                  color="white"
                  :href="termsLink"
                  target="_blank"
                >
                  <span class="caption"> {{ $t("misc.termsOfService") }} </span>
                </v-btn>
              </v-col>
            </v-card-text>
          </v-card>
        </v-menu>
      </v-row>
    </v-app-bar>
    <!-- Email Invitations Dialog -->
    <v-dialog v-model="inviteDialog" persistent max-width="560">
      <v-card v-if="selectedOrganisation">
        <v-card-title class="title font-weight-regular justify-space-between">
          <span>{{    $t("organisations.invitePeopleTo") + selectedOrganisation.name }}</span>
          <v-btn
            :disabled="
              emailsInvitationLoading == 1 || emailsInvitationLoading == 2
            "
            icon
            @click="closeInviteDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="text-center">
          <v-window v-model="emailsInvitationLoading">
            <!-- Emails to invite -->
            <v-window-item :value="0">
              <v-combobox
                ref="emailTextInput"
                v-model="emailsToInvite"
                :label="placeholderInvitation"
                multiple
                deletable-chips
                :delimiters="[',', ' ']"
                small-chips
                validate-on-blur
                filled
                chip-color="button_blue"
                solo
                flat
                dense
                prepend-icon="mdi-account-multiple-plus"
                @change="parseMails"
                :rules="[
                  (e) =>
                    !emailsToInvite ||
                    validEmails ||
                    $t('organisations.invalidEmailMsg'),
                ]"
              ></v-combobox>

              <span>{{ $t("organisations.youCanInviteMultiplePeople") }}</span>
            </v-window-item>

            <!-- Loading animation-->
            <v-window-item :value="1">
              <ripple-loader color="blue" />
              <h4>{{ $t("organisations.sendInvitations") }}</h4>
              <span>{{ $t("misc.pleaseWait") }}</span>
            </v-window-item>

            <!-- Success ! -->
            <v-window-item :value="2">
              <v-icon size="72" color="success">mdi-email-check</v-icon>
              <h4>{{ $t("organisations.invitationsSended") }}</h4>
            </v-window-item>

            <!-- Error! -->
            <v-window-item :value="3">
              <v-icon size="64" color="error">mdi-email-alert</v-icon>
              <h4>{{ $t("organisations.errorWhileSendingInvitation") }}</h4>
              <span>{{ $t("organisations.someInvitationError") }}</span>
            </v-window-item>
          </v-window>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions v-show="emailsInvitationLoading != 2">
          <v-btn
            v-if="emailsInvitationLoading == 0"
            small
            color="almost_black"
            text
            @click="closeInviteDialog()"
          >
            {{ $t("misc.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :loading="emailsInvitationLoading > 0"
            v-if="emailsInvitationLoading < 2"
            depressed
            small
            color="button_blue"
            class="white--text"
            @click="sendInvitations"
          >
            <v-icon small left>mdi-send</v-icon>
            {{ $t("organisations.sendInvitations") }}
          </v-btn>

          <v-btn
            v-if="emailsInvitationLoading == 3"
            depressed
            small
            dark
            text
            color="almost_black"
            @click="closeInviteDialog()"
          >
            <v-icon small left>mdi-close</v-icon>
            {{ $t("misc.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BULK_CREATE_ORGANISATION_USER from "@/graphql/organisation_users/bulk_create.gql";
import userAvatar from "./userAvatar";

export default {
  name: "HeaderToolbar",
  mixins: [],
  components: { userAvatar },
  props: {
    color: { type: String, default: "transparent", required: false },
    dark: { type: Boolean, default: false, required: false },
    app: { type: Boolean, default: true, required: false },
    height: { type: Number, default: 72, required: false },
    toolbarTitleClass: { type: String, default: "ml-3", required: false },
    hideSettings: {
      type: Boolean,
      default: false,
      required: false,
    },
    divider: {
      type: Boolean,
      default: false,
      required: false,
    },
    preventOrgSwitch: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      emailsToInvite: [],
      emailsToInviteErrors: [],
      emailsToInviteError: "",
      emailsInvitationLoading: 0,
      email: "",
      inviteDialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "organisations",
      "selectedOrganisationId",
      "selectedOrganisation",
    ]),
    termsLink() {
      if (this.$i18n.locale === "fr")
        return "https://www.getcollective.com/fr/terms";
      return "https://www.getcollective.com/en/terms";
    },
    privacyLink() {
      if (this.$i18n.locale === "fr")
        return "https://www.getcollective.com/fr/privacy";
      return "https://www.getcollective.com/en/privacy";
    },
    organisationName() {
      if (!this.selectedOrganisation) return "";
      return this.selectedOrganisation.name;
    },
    placeholderInvitation() {
      return `email@${
        this.organisationName
          ? this.organisationName.toLowerCase()
          : "missingOrgName"
      }.com`;
    },
    validEmails() {
      return (
        this.emailsToInvite
          .map((e) => !!e.trim().match(/^(.+)@(.+)$/))
          .filter((e) => !e).length === 0
      );
    },
  },
  methods: {
    ...mapActions(["onLoggedOut", "setSelectedOrganisationId"]),
    async logout() {
      await this.onLoggedOut();
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    login() {
      this.$auth.loginWithRedirect();
    },
    //...mapActions(["logOut"]),
    openNotificationMenu() {
      alert("Open Notification Menu");
    },
    goToOrganisation(org_id) {
      if (this.selectedOrganisationId != org_id)
        this.setSelectedOrganisationId(org_id);
    },
    sendInvitations() {
      // Input box change event is trigger by the blur function so the parsemail event is working, then we wait the next tick to send invitations
      this.$refs.emailTextInput.blur();
      this.$nextTick(() => {
        if (this.loading === true) return;
        if (!this.validEmails)
          return this.sendError(this.$t("organisations.invalidEmailsMsg"));
        this.emailsInvitationLoading = 1;
        // this.emailsInvitationLoading = 0: not loading, 1: loading, 2: sucess, 3: error
        this.emailsToInviteErrors = [];
        this.$apollo
          .mutate({
            mutation: BULK_CREATE_ORGANISATION_USER,
            variables: {
              invited_user_emails: this.emailsToInvite,
              organisation_id: this.selectedOrganisationId,
            },
          })
          .then(({ data }) => {
            console.log("Data from invitation: ", data);

            if (data.bulk_create_organisation_user.errors.length == 0) {
              this.emailsInvitationLoading = 2;
              console.log("0 errors")
              this.$emit('invitationSended');
              setTimeout(() => this.closeInviteDialog(), 2000);
            } else {
               console.log("EEEerrors")
              this.emailsInvitationLoading = 3;
              this.emailsToInviteErrors =
                data.bulk_create_organisation_invitation.errors;
            }
          })
          .catch((error) => {
             console.log("EEEerrors WHAT", error)
            this.emailsInvitationLoading = 3;
            this.emailsToInviteError = error;
          });
      });
    },
    closeInviteDialog() {
      this.inviteDialog = false;
      this.emailsInvitationLoading = 0;
      this.emailsToInvite = [];
      this.emailsToInviteErrors = [];
      this.emailsToInviteError = "";
    },
    parseMails(v) {
      const reducer = (a, e) => [...a, ...e.split(/[, ]+/)];
      this.emailsToInvite = [...new Set(v.reduce(reducer, []))];
    },
  },
  mounted() {

    // const script = document.createElement("script");
    // script.src="https://js-na1.hs-scripts.com/7923923.js";
    // document.body.appendChild(script);




  },
  beforeDestroy() {},
};
</script>
